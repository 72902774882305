import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Home = () => {
  return <h1>Welcome to PeonyPocket</h1>;
};

const PrivacyPolicy = () => {
  return (
    <>
      <div data-custom-class='body'>
        <div>
          <strong>
            <span styleProp='font-size: 26px;'>
              <span data-custom-class='title'>
                <bdt class='block-component'></bdt>
                <bdt class='question'>PRIVACY POLICY</bdt>
                <bdt class='statement-end-if-in-editor'></bdt>
              </span>
            </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
          <span styleProp='color: rgb(127, 127, 127);'>
            <strong>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='subtitle'>
                  Last updated <bdt class='question'>October 30, 2023</bdt>
                </span>
              </span>
            </strong>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div styleProp='line-height: 1.5;'>
          <span styleProp='color: rgb(127, 127, 127);'>
            <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
              <span data-custom-class='body_text'>
                This privacy notice for{' '}
                <bdt class='question'>
                  PeonyPocket LLC<bdt class='block-component'></bdt>
                </bdt>{' '}
                (<bdt class='block-component'></bdt>"<strong>we</strong>," "
                <strong>us</strong>," or "<strong>our</strong>"
                <bdt class='statement-end-if-in-editor'></bdt>
              </span>
              <span data-custom-class='body_text'>
                ), describes how and why we might collect, store, use, and/or
                share (<bdt class='block-component'></bdt>"
                <strong>process</strong>"
                <bdt class='statement-end-if-in-editor'></bdt>) your information
                when you use our services (<bdt class='block-component'></bdt>"
                <strong>Services</strong>"
                <bdt class='statement-end-if-in-editor'></bdt>), such as when
                you:
              </span>
            </span>
          </span>
          <span styleProp='font-size: 15px;'>
            <span styleProp='color: rgb(127, 127, 127);'>
              <span data-custom-class='body_text'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <bdt class='block-component'></bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  Visit our website<bdt class='block-component'></bdt> at{' '}
                  <bdt class='question'>
                    <a
                      href='http://www.peonypocket.com'
                      target='_blank'
                      data-custom-class='link'
                    >
                      http://www.peonypocket.com
                    </a>
                  </bdt>
                  <span styleProp='font-size: 15px;'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <span data-custom-class='body_text'>
                        <span styleProp='font-size: 15px;'>
                          <span styleProp='color: rgb(89, 89, 89);'>
                            <bdt class='statement-end-if-in-editor'>
                              , or any website of ours that links to this
                              privacy notice
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <bdt class='block-component'>
            <span styleProp='font-size: 15px;'>
              <span styleProp='font-size: 15px;'>
                <span styleProp='color: rgb(127, 127, 127);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <span data-custom-class='body_text'>
                        <bdt class='block-component'></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </bdt>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span data-custom-class='body_text'>
                  <span styleProp='color: rgb(89, 89, 89);'>
                    <span data-custom-class='body_text'>
                      <bdt class='block-component'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li styleProp='line-height: 1.5;'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                    <span styleProp='font-size: 15px;'>
                      <span styleProp='color: rgb(89, 89, 89);'>
                        <span data-custom-class='body_text'>
                          <span styleProp='font-size: 15px;'>
                            <span styleProp='color: rgb(89, 89, 89);'>
                              <bdt class='statement-end-if-in-editor'></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span data-custom-class='body_text'>
                  <strong>Questions or concerns? </strong>Reading this privacy
                  notice will help you understand your privacy rights and
                  choices. If you do not agree with our policies and practices,
                  please do not use our Services.
                  <bdt class='block-component'></bdt> If you still have any
                  questions or concerns, please contact us at{' '}
                  <bdt class='question'>peonypocketdev@gmail.com</bdt>.
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <strong>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='heading_1'>SUMMARY OF KEY POINTS</span>
              </span>
            </strong>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>
                  <em>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our{' '}
                  </em>
                </strong>
              </span>
            </span>
            <a data-custom-class='link' href='#toc'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'>
                  <strong>
                    <em>table of contents</em>
                  </strong>
                </span>
              </span>
            </a>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>
                  <em> below to find the section you are looking for.</em>
                </strong>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>What personal information do we process?</strong> When
                you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use. Learn more about{' '}
              </span>
            </span>
            <a data-custom-class='link' href='#personalinfo'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'>
                  personal information you disclose to us
                </span>
              </span>
            </a>
            <span data-custom-class='body_text'>.</span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>
                  Do we process any sensitive personal information?
                </strong>{' '}
                <bdt class='block-component'></bdt>We do not process sensitive
                personal information.<bdt class='else-block'></bdt>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>
                  Do we receive any information from third parties?
                </strong>{' '}
                <bdt class='block-component'></bdt>We do not receive any
                information from third parties.<bdt class='else-block'></bdt>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>How do we process your information?</strong> We process
                your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about{' '}
              </span>
            </span>
            <a data-custom-class='link' href='#infouse'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'>
                  how we process your information
                </span>
              </span>
            </a>
            <span data-custom-class='body_text'>.</span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>
                  In what situations and with which{' '}
                  <bdt class='block-component'></bdt>parties do we share
                  personal information?
                </strong>{' '}
                We may share information in specific situations and with
                specific <bdt class='block-component'></bdt>third parties. Learn
                more about{' '}
              </span>
            </span>
            <a data-custom-class='link' href='#whoshare'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'>
                  when and with whom we share your personal information
                </span>
              </span>
            </a>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                .<bdt class='block-component'></bdt>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>How do we keep your information safe?</strong> We have{' '}
                <bdt class='block-component'></bdt>organizational
                <bdt class='statement-end-if-in-editor'></bdt> and technical
                processes and procedures in place to protect your personal
                information. However, no electronic transmission over the
                internet or information storage technology can be guaranteed to
                be 100% secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other <bdt class='block-component'></bdt>
                unauthorized<bdt class='statement-end-if-in-editor'></bdt> third
                parties will not be able to defeat our security and improperly
                collect, access, steal, or modify your information. Learn more
                about{' '}
              </span>
            </span>
            <a data-custom-class='link' href='#infosafe'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'>
                  how we keep your information safe
                </span>
              </span>
            </a>
            <span data-custom-class='body_text'>.</span>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <bdt class='statement-end-if-in-editor'></bdt>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>What are your rights?</strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.
                Learn more about{' '}
              </span>
            </span>
            <a data-custom-class='link' href='#privacyrights'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'>your privacy rights</span>
              </span>
            </a>
            <span data-custom-class='body_text'>.</span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>How do you exercise your rights?</strong> The easiest
                way to exercise your rights is by{' '}
                <bdt class='block-component'></bdt>visiting{' '}
                <bdt class='question'>
                  <a
                    href='http://www.peonypocket.com'
                    target='_blank'
                    data-custom-class='link'
                  >
                    http://www.peonypocket.com
                  </a>
                </bdt>
                <bdt class='else-block'></bdt>, or by contacting us. We will
                consider and act upon any request in accordance with applicable
                data protection laws.
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                Want to learn more about what we do with any information we
                collect?{' '}
              </span>
            </span>
            <a data-custom-class='link' href='#toc'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'>
                  Review the privacy notice in full
                </span>
              </span>
            </a>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>.</span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div id='toc' styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(0, 0, 0);'>
                  <strong>
                    <span data-custom-class='heading_1'>TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#infocollect'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
              </a>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#infouse'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                  <bdt class='block-component'></bdt>
                </span>
              </a>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#legalbases'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  3.{' '}
                  <span styleProp='font-size: 15px;'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?
                    </span>
                  </span>
                  <bdt class='statement-end-if-in-editor'></bdt>
                </span>
              </a>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span styleProp='color: rgb(89, 89, 89);'>
                <a data-custom-class='link' href='#whoshare'>
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </span>
              <span data-custom-class='body_text'>
                <bdt class='block-component'></bdt>
              </span>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <bdt class='block-component'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#cookies'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </span>
              </a>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <bdt class='statement-end-if-in-editor'></bdt>
                    </span>
                  </span>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <span styleProp='color: rgb(89, 89, 89);'>
                        <span styleProp='color: rgb(89, 89, 89);'>
                          <bdt class='block-component'></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#sociallogins'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span styleProp='color: rgb(89, 89, 89);'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </span>
                  </span>
                </span>
              </a>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <span styleProp='color: rgb(89, 89, 89);'>
                        <span styleProp='color: rgb(89, 89, 89);'>
                          <bdt class='statement-end-if-in-editor'></bdt>
                        </span>
                      </span>
                      <bdt class='block-component'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#inforetain'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  7. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </a>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <span styleProp='color: rgb(89, 89, 89);'>
                        <bdt class='block-component'></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#infosafe'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </a>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <bdt class='statement-end-if-in-editor'></bdt>
                      <bdt class='block-component'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#infominors'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  9. DO WE COLLECT INFORMATION FROM MINORS?
                </span>
              </a>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='color: rgb(89, 89, 89);'>
                      <bdt class='statement-end-if-in-editor'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span styleProp='color: rgb(89, 89, 89);'>
                <a data-custom-class='link' href='#privacyrights'>
                  10. WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#DNT'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  11. CONTROLS FOR DO-NOT-TRACK FEATURES
                  <bdt class='block-component'></bdt>
                </span>
              </a>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#uslaws'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </a>
            </span>
            <bdt class='block-component'>
              <span styleProp='font-size: 15px;'>
                <span data-custom-class='body_text'></span>
              </span>
            </bdt>
          </div>
          <div styleProp='line-height: 1.5;'>
            <bdt class='block-component'>
              <span styleProp='font-size: 15px;'></span>
            </bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
            <bdt class='block-component'></bdt>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <a data-custom-class='link' href='#policyupdates'>
                <span styleProp='color: rgb(89, 89, 89);'>
                  13. DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
              </a>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <a data-custom-class='link' href='#contact'>
              <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </div>
          <div styleProp='line-height: 1.5;'>
            <a data-custom-class='link' href='#request'>
              <span styleProp='color: rgb(89, 89, 89);'>
                15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </a>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div id='infocollect' styleProp='line-height: 1.5;'>
            <span styleProp='color: rgb(127, 127, 127);'>
              <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                    <span id='control' styleProp='color: rgb(0, 0, 0);'>
                      <strong>
                        <span data-custom-class='heading_1'>
                          1. WHAT INFORMATION DO WE COLLECT?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div id='personalinfo' styleProp='line-height: 1.5;'>
            <span
              data-custom-class='heading_2'
              styleProp='color: rgb(0, 0, 0);'
            >
              <span styleProp='font-size: 15px;'>
                <strong>Personal information you disclose to us</strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div styleProp='line-height: 1.5;'>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                  <span data-custom-class='body_text'>
                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                        <span data-custom-class='body_text'>
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class='body_text'>
                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                        <span data-custom-class='body_text'>
                          <strong>
                            <em> </em>
                          </strong>
                          <em>
                            We collect personal information that you provide to
                            us.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  We collect personal information that you voluntarily provide
                  to us when you{' '}
                  <span styleProp='font-size: 15px;'>
                    <bdt class='block-component'></bdt>
                  </span>
                  register on the Services,{' '}
                </span>
                <span styleProp='font-size: 15px;'>
                  <span data-custom-class='body_text'>
                    <span styleProp='font-size: 15px;'>
                      <bdt class='statement-end-if-in-editor'></bdt>
                    </span>
                  </span>
                  <span data-custom-class='body_text'>
                    express an interest in obtaining information about us or our
                    products and Services, when you participate in activities on
                    the Services, or otherwise when you contact us.
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <span styleProp='font-size: 15px;'>
                    <bdt class='block-component'></bdt>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <strong>Personal Information Provided by You.</strong> The
                  personal information that we collect depends on the context of
                  your interactions with us and the Services, the choices you
                  make, and the products and features you use. The personal
                  information we collect may include the following:
                  <span styleProp='font-size: 15px;'>
                    <span data-custom-class='body_text'>
                      <bdt class='forloop-component'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li styleProp='line-height: 1.5;'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='font-size: 15px;'>
                      <span data-custom-class='body_text'>
                        <bdt class='question'>email addresses</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <span styleProp='font-size: 15px;'>
                    <span data-custom-class='body_text'>
                      <bdt class='forloop-component'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li styleProp='line-height: 1.5;'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <span styleProp='font-size: 15px;'>
                      <span data-custom-class='body_text'>
                        <bdt class='question'>usernames</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <span styleProp='font-size: 15px;'>
                    <span data-custom-class='body_text'>
                      <bdt class='forloop-component'></bdt>
                    </span>
                    <span data-custom-class='body_text'>
                      <bdt class='statement-end-if-in-editor'></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div id='sensitiveinfo' styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <strong>Sensitive Information.</strong>{' '}
                <bdt class='block-component'></bdt>We do not process sensitive
                information.
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <bdt class='else-block'></bdt>
              </span>
            </span>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <span styleProp='font-size: 15px;'>
                    <span data-custom-class='body_text'>
                      <bdt class='block-component'>
                        <bdt class='block-component'></bdt>
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <strong>Social Media Login Data. </strong>We may provide you
                  with the option to register with us using your existing social
                  media account details, like your Facebook, Twitter, or other
                  social media account. If you choose to register in this way,
                  we will collect the information described in the section
                  called <bdt class='block-component'></bdt>"
                  <bdt class='statement-end-if-in-editor'></bdt>
                  <span styleProp='font-size: 15px;'>
                    <span data-custom-class='body_text'>
                      <span styleProp='font-size: 15px;'>
                        <span styleProp='color: rgb(89, 89, 89);'>
                          <a data-custom-class='link' href='#sociallogins'>
                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                          </a>
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt class='block-component'></bdt>"
                  <bdt class='statement-end-if-in-editor'></bdt> below.
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <span styleProp='font-size: 15px;'>
                    <bdt class='statement-end-if-in-editor'>
                      <bdt class='statement-end-if-in-editor'></bdt>
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
            <bdt class='block-component'></bdt>
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </span>
              </span>
            </span>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <bdt class='block-component'></bdt>
                </span>
                <span data-custom-class='body_text'>
                  <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                    <span data-custom-class='body_text'>
                      <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                        <span data-custom-class='body_text'>
                          <bdt class='statement-end-if-in-editor'>
                            <bdt class='block-component'></bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span styleProp='font-size: 15px;'>
              <span data-custom-class='body_text'>
                <bdt class='block-component'></bdt>
              </span>
            </span>
          </div>
          <div id='infouse' styleProp='line-height: 1.5;'>
            <span styleProp='color: rgb(127, 127, 127);'>
              <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                    <span id='control' styleProp='color: rgb(0, 0, 0);'>
                      <strong>
                        <span data-custom-class='heading_1'>
                          2. HOW DO WE PROCESS YOUR INFORMATION?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <div styleProp='line-height: 1.5;'>
              <br />
            </div>
            <div styleProp='line-height: 1.5;'>
              <span styleProp='color: rgb(127, 127, 127);'>
                <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                  <span data-custom-class='body_text'>
                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                        <span data-custom-class='body_text'>
                          <strong>
                            <em>In Short: </em>
                          </strong>
                          <em>
                            We process your information to provide, improve, and
                            administer our Services, communicate with you, for
                            security and fraud prevention, and to comply with
                            law. We may also process your information for other
                            purposes with your consent.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div styleProp='line-height: 1.5;'>
            <br />
          </div>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <strong>
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                  </strong>
                  <bdt class='block-component'></bdt>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li styleProp='line-height: 1.5;'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <strong>
                      To facilitate account creation and authentication and
                      otherwise manage user accounts.{' '}
                    </strong>
                    We may process your information so you can create and log in
                    to your account, as well as keep your account in working
                    order.
                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                        <span data-custom-class='body_text'>
                          <span styleProp='font-size: 15px;'>
                            <span styleProp='color: rgb(89, 89, 89);'>
                              <span data-custom-class='body_text'>
                                <span styleProp='font-size: 15px;'>
                                  <span styleProp='color: rgb(89, 89, 89);'>
                                    <span data-custom-class='body_text'>
                                      <bdt class='statement-end-if-in-editor'></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div styleProp='line-height: 1.5;'>
            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span data-custom-class='body_text'>
                  <bdt class='block-component'></bdt>
                </span>
              </span>
            </span>
            <div styleProp='line-height: 1.5;'>
              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span data-custom-class='body_text'>
                    <bdt class='block-component'></bdt>
                  </span>
                </span>
              </span>
              <div styleProp='line-height: 1.5;'>
                <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                    <span data-custom-class='body_text'>
                      <bdt class='block-component'></bdt>
                    </span>
                  </span>
                </span>
                <div styleProp='line-height: 1.5;'>
                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                      <span data-custom-class='body_text'>
                        <bdt class='block-component'></bdt>
                      </span>
                    </span>
                  </span>
                  <div styleProp='line-height: 1.5;'>
                    <bdt class='block-component'>
                      <span styleProp='font-size: 15px;'></span>
                    </bdt>
                    <div styleProp='line-height: 1.5;'>
                      <bdt class='block-component'>
                        <span styleProp='font-size: 15px;'></span>
                      </bdt>
                      <div styleProp='line-height: 1.5;'>
                        <bdt class='block-component'>
                          <span styleProp='font-size: 15px;'></span>
                        </bdt>
                        <div styleProp='line-height: 1.5;'>
                          <bdt class='block-component'>
                            <span styleProp='font-size: 15px;'>
                              <span data-custom-class='body_text'></span>
                            </span>
                          </bdt>
                          <p styleProp='font-size: 15px; line-height: 1.5;'>
                            <bdt class='block-component'>
                              <span styleProp='font-size: 15px;'></span>
                            </bdt>
                          </p>
                          <p styleProp='font-size: 15px; line-height: 1.5;'>
                            <bdt class='block-component'>
                              <span styleProp='font-size: 15px;'></span>
                            </bdt>
                          </p>
                          <p styleProp='font-size: 15px; line-height: 1.5;'>
                            <bdt class='block-component'></bdt>
                          </p>
                          <p styleProp='font-size: 15px; line-height: 1.5;'>
                            <bdt class='block-component'></bdt>
                          </p>
                          <div styleProp='line-height: 1.5;'>
                            <bdt class='block-component'>
                              <span styleProp='font-size: 15px;'>
                                <span data-custom-class='body_text'></span>
                              </span>
                            </bdt>
                            <div styleProp='line-height: 1.5;'>
                              <bdt class='block-component'>
                                <span styleProp='font-size: 15px;'></span>
                              </bdt>
                              <div styleProp='line-height: 1.5;'>
                                <bdt class='block-component'>
                                  <span styleProp='font-size: 15px;'></span>
                                </bdt>
                                <div styleProp='line-height: 1.5;'>
                                  <span styleProp='font-size: 15px;'>
                                    <bdt class='block-component'>
                                      <span data-custom-class='body_text'></span>
                                    </bdt>
                                  </span>
                                  <div styleProp='line-height: 1.5;'>
                                    <bdt class='block-component'>
                                      <span styleProp='font-size: 15px;'>
                                        <span data-custom-class='body_text'></span>
                                      </span>
                                    </bdt>
                                    <div styleProp='line-height: 1.5;'>
                                      <bdt class='block-component'>
                                        <span styleProp='font-size: 15px;'>
                                          <span data-custom-class='body_text'></span>
                                        </span>
                                      </bdt>
                                      <div styleProp='line-height: 1.5;'>
                                        <bdt class='block-component'>
                                          <span styleProp='font-size: 15px;'>
                                            <span data-custom-class='body_text'></span>
                                          </span>
                                        </bdt>
                                        <div styleProp='line-height: 1.5;'>
                                          <bdt class='block-component'>
                                            <span styleProp='font-size: 15px;'>
                                              <span data-custom-class='body_text'></span>
                                            </span>
                                          </bdt>
                                          <div styleProp='line-height: 1.5;'>
                                            <bdt class='block-component'>
                                              <span styleProp='font-size: 15px;'>
                                                <span data-custom-class='body_text'></span>
                                              </span>
                                            </bdt>
                                            <div styleProp='line-height: 1.5;'>
                                              <bdt class='block-component'>
                                                <span styleProp='font-size: 15px;'>
                                                  <span data-custom-class='body_text'></span>
                                                </span>
                                              </bdt>
                                              <div styleProp='line-height: 1.5;'>
                                                <bdt class='block-component'>
                                                  <span styleProp='font-size: 15px;'>
                                                    <span data-custom-class='body_text'></span>
                                                  </span>
                                                </bdt>
                                                <div styleProp='line-height: 1.5;'>
                                                  <bdt class='block-component'>
                                                    <span styleProp='font-size: 15px;'>
                                                      <span data-custom-class='body_text'></span>
                                                    </span>
                                                  </bdt>
                                                  <div styleProp='line-height: 1.5;'>
                                                    <bdt class='block-component'>
                                                      <span styleProp='font-size: 15px;'>
                                                        <span data-custom-class='body_text'></span>
                                                      </span>
                                                    </bdt>
                                                    <div styleProp='line-height: 1.5;'>
                                                      <bdt class='block-component'>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul>
                                                      <li styleProp='line-height: 1.5;'>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'>
                                                            <strong>
                                                              To comply with our
                                                              legal obligations.
                                                            </strong>{' '}
                                                            We may process your
                                                            information to
                                                            comply with our
                                                            legal obligations,
                                                            respond to legal
                                                            requests, and
                                                            exercise, establish,
                                                            or defend our legal
                                                            rights.
                                                            <bdt class='statement-end-if-in-editor'></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div styleProp='line-height: 1.5;'>
                                                      <bdt class='block-component'>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'></span>
                                                        </span>
                                                      </bdt>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div
                                                        id='legalbases'
                                                        styleProp='line-height: 1.5;'
                                                      >
                                                        <strong>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='heading_1'>
                                                              3. WHAT LEGAL
                                                              BASES DO WE RELY
                                                              ON TO PROCESS YOUR
                                                              INFORMATION?
                                                            </span>
                                                          </span>
                                                        </strong>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <em>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'>
                                                              <strong>
                                                                In Short:{' '}
                                                              </strong>
                                                              We only process
                                                              your personal
                                                              information when
                                                              we believe it is
                                                              necessary and we
                                                              have a valid legal
                                                              reason (i.e.
                                                              <bdt class='block-component'></bdt>
                                                              ,
                                                              <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                              legal basis) to do
                                                              so under
                                                              applicable law,
                                                              like with your
                                                              consent, to comply
                                                              with laws, to
                                                              provide you with
                                                              services to enter
                                                              into or{' '}
                                                              <bdt class='block-component'></bdt>
                                                              fulfill
                                                              <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                              our contractual
                                                              obligations, to
                                                              protect your
                                                              rights, or to{' '}
                                                              <bdt class='block-component'></bdt>
                                                              fulfill
                                                              <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                              our legitimate
                                                              business
                                                              interests.
                                                            </span>
                                                          </span>
                                                        </em>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <bdt class='block-component'>
                                                              <bdt class='block-component'></bdt>
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <strong>
                                                              <u>
                                                                <em>
                                                                  If you are
                                                                  located in
                                                                  Canada, this
                                                                  section
                                                                  applies to
                                                                  you.
                                                                </em>
                                                              </u>
                                                            </strong>
                                                            <bdt class='statement-end-if-in-editor'></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            We may process your
                                                            information if you
                                                            have given us
                                                            specific permission
                                                            (i.e.
                                                            <bdt class='block-component'></bdt>
                                                            ,
                                                            <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                            express consent) to
                                                            use your personal
                                                            information for a
                                                            specific purpose, or
                                                            in situations where
                                                            your permission can
                                                            be inferred (i.e.
                                                            <bdt class='block-component'></bdt>
                                                            ,
                                                            <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                            implied consent).
                                                            You can{' '}
                                                          </span>
                                                        </span>
                                                        <a
                                                          data-custom-class='link'
                                                          href='#withdrawconsent'
                                                        >
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              withdraw your
                                                              consent
                                                            </span>
                                                          </span>
                                                        </a>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            {' '}
                                                            at any time.
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            In some exceptional
                                                            cases, we may be
                                                            legally permitted
                                                            under applicable law
                                                            to process your
                                                            information without
                                                            your consent,
                                                            including, for
                                                            example:
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              If collection is
                                                              clearly in the
                                                              interests of an
                                                              individual and
                                                              consent cannot be
                                                              obtained in a
                                                              timely way
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              For investigations
                                                              and fraud
                                                              detection and
                                                              prevention
                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              For business
                                                              transactions
                                                              provided certain
                                                              conditions are met
                                                            </span>
                                                          </span>
                                                          <bdt class='statement-end-if-in-editor'>
                                                            <span styleProp='font-size: 15px;'>
                                                              <span data-custom-class='body_text'></span>
                                                            </span>
                                                          </bdt>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              If it is contained
                                                              in a witness
                                                              statement and the
                                                              collection is
                                                              necessary to
                                                              assess, process,
                                                              or settle an
                                                              insurance claim
                                                            </span>
                                                          </span>
                                                          <bdt class='statement-end-if-in-editor'>
                                                            <span styleProp='font-size: 15px;'>
                                                              <span data-custom-class='body_text'></span>
                                                            </span>
                                                          </bdt>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              For identifying
                                                              injured, ill, or
                                                              deceased persons
                                                              and communicating
                                                              with next of kin
                                                            </span>
                                                          </span>
                                                          <bdt class='statement-end-if-in-editor'>
                                                            <span styleProp='font-size: 15px;'>
                                                              <span data-custom-class='body_text'></span>
                                                            </span>
                                                          </bdt>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              If we have
                                                              reasonable grounds
                                                              to believe an
                                                              individual has
                                                              been, is, or may
                                                              be victim of
                                                              financial abuse
                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              If it is
                                                              reasonable to
                                                              expect collection
                                                              and use with
                                                              consent would
                                                              compromise the
                                                              availability or
                                                              the accuracy of
                                                              the information
                                                              and the collection
                                                              is reasonable for
                                                              purposes related
                                                              to investigating a
                                                              breach of an
                                                              agreement or a
                                                              contravention of
                                                              the laws of Canada
                                                              or a province
                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span data-custom-class='body_text'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span data-custom-class='body_text'>
                                                            <span styleProp='font-size: 15px;'>
                                                              If disclosure is
                                                              required to comply
                                                              with a subpoena,
                                                              warrant, court
                                                              order, or rules of
                                                              the court relating
                                                              to the production
                                                              of records
                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <bdt class='block-component'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'>
                                                              If it was produced
                                                              by an individual
                                                              in the course of
                                                              their employment,
                                                              business, or
                                                              profession and the
                                                              collection is
                                                              consistent with
                                                              the purposes for
                                                              which the
                                                              information was
                                                              produced
                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'>
                                                              If the collection
                                                              is solely for
                                                              journalistic,
                                                              artistic, or
                                                              literary purposes
                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'>
                                                              If the information
                                                              is publicly
                                                              available and is
                                                              specified by the
                                                              regulations
                                                            </span>
                                                            <bdt class='statement-end-if-in-editor'>
                                                              <span data-custom-class='body_text'></span>
                                                            </bdt>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <bdt class='statement-end-if-in-editor'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                        <bdt class='statement-end-if-in-editor'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'></span>
                                                          </span>
                                                        </bdt>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div
                                                        id='whoshare'
                                                        styleProp='line-height: 1.5;'
                                                      >
                                                        <span styleProp='color: rgb(127, 127, 127);'>
                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                <span
                                                                  id='control'
                                                                  styleProp='color: rgb(0, 0, 0);'
                                                                >
                                                                  <strong>
                                                                    <span data-custom-class='heading_1'>
                                                                      4. WHEN
                                                                      AND WITH
                                                                      WHOM DO WE
                                                                      SHARE YOUR
                                                                      PERSONAL
                                                                      INFORMATION?
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                            <span data-custom-class='body_text'>
                                                              <strong>
                                                                <em>
                                                                  In Short:
                                                                </em>
                                                              </strong>
                                                              <em>
                                                                {' '}
                                                                We may share
                                                                information in
                                                                specific
                                                                situations
                                                                described in
                                                                this section
                                                                and/or with the
                                                                following{' '}
                                                                <bdt class='block-component'></bdt>
                                                                third parties.
                                                              </em>
                                                            </span>
                                                          </span>{' '}
                                                        </span>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                            <span data-custom-class='body_text'>
                                                              <bdt class='block-component'></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <br />
                                                      </div>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'>
                                                            We{' '}
                                                            <bdt class='block-component'></bdt>
                                                            may need to share
                                                            your personal
                                                            information in the
                                                            following
                                                            situations:
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <ul>
                                                        <li styleProp='line-height: 1.5;'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <span data-custom-class='body_text'>
                                                              <strong>
                                                                Business
                                                                Transfers.
                                                              </strong>{' '}
                                                              We may share or
                                                              transfer your
                                                              information in
                                                              connection with,
                                                              or during
                                                              negotiations of,
                                                              any merger, sale
                                                              of company assets,
                                                              financing, or
                                                              acquisition of all
                                                              or a portion of
                                                              our business to
                                                              another company.
                                                            </span>
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <div styleProp='line-height: 1.5;'>
                                                        <span styleProp='font-size: 15px;'>
                                                          <span data-custom-class='body_text'>
                                                            <bdt class='block-component'></bdt>
                                                          </span>
                                                        </span>
                                                        <div styleProp='line-height: 1.5;'>
                                                          <span styleProp='font-size: 15px;'>
                                                            <bdt class='block-component'>
                                                              <span data-custom-class='body_text'></span>
                                                            </bdt>
                                                          </span>
                                                          <div styleProp='line-height: 1.5;'>
                                                            <bdt class='block-component'>
                                                              <span styleProp='font-size: 15px;'>
                                                                <span data-custom-class='body_text'></span>
                                                              </span>
                                                            </bdt>
                                                            <div styleProp='line-height: 1.5;'>
                                                              <bdt class='block-component'>
                                                                <span styleProp='font-size: 15px;'>
                                                                  <span data-custom-class='body_text'></span>
                                                                </span>
                                                              </bdt>
                                                              <div styleProp='line-height: 1.5;'>
                                                                <bdt class='block-component'>
                                                                  <span styleProp='font-size: 15px;'>
                                                                    <span data-custom-class='body_text'></span>
                                                                  </span>
                                                                </bdt>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <bdt class='block-component'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span data-custom-class='body_text'></span>
                                                                    </span>
                                                                  </bdt>
                                                                  <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span styleProp='color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px;'>
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <bdt class='block-component'>
                                                                              <span data-custom-class='heading_1'></span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='cookies'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                5.
                                                                                DO
                                                                                WE
                                                                                USE
                                                                                COOKIES
                                                                                AND
                                                                                OTHER
                                                                                TRACKING
                                                                                TECHNOLOGIES?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          {' '}
                                                                          We may
                                                                          use
                                                                          cookies
                                                                          and
                                                                          other
                                                                          tracking
                                                                          technologies
                                                                          to
                                                                          collect
                                                                          and
                                                                          store
                                                                          your
                                                                          information.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        We may
                                                                        use
                                                                        cookies
                                                                        and
                                                                        similar
                                                                        tracking
                                                                        technologies
                                                                        (like
                                                                        web
                                                                        beacons
                                                                        and
                                                                        pixels)
                                                                        to
                                                                        access
                                                                        or store
                                                                        information.
                                                                        Specific
                                                                        information
                                                                        about
                                                                        how we
                                                                        use such
                                                                        technologies
                                                                        and how
                                                                        you can
                                                                        refuse
                                                                        certain
                                                                        cookies
                                                                        is set
                                                                        out in
                                                                        our
                                                                        Cookie
                                                                        Notice
                                                                        <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                          <span data-custom-class='body_text'>
                                                                            <bdt class='block-component'></bdt>
                                                                            .
                                                                          </span>
                                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                            <span styleProp='font-size: 15px;'>
                                                                              <span styleProp='color: rgb(89, 89, 89);'>
                                                                                <span styleProp='font-size: 15px;'>
                                                                                  <span styleProp='color: rgb(89, 89, 89);'>
                                                                                    <span data-custom-class='body_text'>
                                                                                      <bdt class='statement-end-if-in-editor'></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class='body_text'>
                                                                        <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                            <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                              <span styleProp='font-size: 15px;'>
                                                                                <span styleProp='color: rgb(89, 89, 89);'>
                                                                                  <span styleProp='font-size: 15px;'>
                                                                                    <span styleProp='color: rgb(89, 89, 89);'>
                                                                                      <span data-custom-class='body_text'>
                                                                                        <bdt class='block-component'></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='sociallogins'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                6.
                                                                                HOW
                                                                                DO
                                                                                WE
                                                                                HANDLE
                                                                                YOUR
                                                                                SOCIAL
                                                                                LOGINS?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:{' '}
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          If you
                                                                          choose
                                                                          to
                                                                          register
                                                                          or log
                                                                          in to
                                                                          our
                                                                          Services
                                                                          using
                                                                          a
                                                                          social
                                                                          media
                                                                          account,
                                                                          we may
                                                                          have
                                                                          access
                                                                          to
                                                                          certain
                                                                          information
                                                                          about
                                                                          you.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        Our
                                                                        Services
                                                                        offer
                                                                        you the
                                                                        ability
                                                                        to
                                                                        register
                                                                        and log
                                                                        in using
                                                                        your
                                                                        third-party
                                                                        social
                                                                        media
                                                                        account
                                                                        details
                                                                        (like
                                                                        your
                                                                        Facebook
                                                                        or
                                                                        Twitter
                                                                        logins).
                                                                        Where
                                                                        you
                                                                        choose
                                                                        to do
                                                                        this, we
                                                                        will
                                                                        receive
                                                                        certain
                                                                        profile
                                                                        information
                                                                        about
                                                                        you from
                                                                        your
                                                                        social
                                                                        media
                                                                        provider.
                                                                        The
                                                                        profile
                                                                        information
                                                                        we
                                                                        receive
                                                                        may vary
                                                                        depending
                                                                        on the
                                                                        social
                                                                        media
                                                                        provider
                                                                        concerned,
                                                                        but will
                                                                        often
                                                                        include
                                                                        your
                                                                        name,
                                                                        email
                                                                        address,
                                                                        friends
                                                                        list,
                                                                        and
                                                                        profile
                                                                        picture,
                                                                        as well
                                                                        as other
                                                                        information
                                                                        you
                                                                        choose
                                                                        to make
                                                                        public
                                                                        on such
                                                                        a social
                                                                        media
                                                                        platform.{' '}
                                                                        <span styleProp='font-size: 15px;'>
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        We will
                                                                        use the
                                                                        information
                                                                        we
                                                                        receive
                                                                        only for
                                                                        the
                                                                        purposes
                                                                        that are
                                                                        described
                                                                        in this
                                                                        privacy
                                                                        notice
                                                                        or that
                                                                        are
                                                                        otherwise
                                                                        made
                                                                        clear to
                                                                        you on
                                                                        the
                                                                        relevant
                                                                        Services.
                                                                        Please
                                                                        note
                                                                        that we
                                                                        do not
                                                                        control,
                                                                        and are
                                                                        not
                                                                        responsible
                                                                        for,
                                                                        other
                                                                        uses of
                                                                        your
                                                                        personal
                                                                        information
                                                                        by your
                                                                        third-party
                                                                        social
                                                                        media
                                                                        provider.
                                                                        We
                                                                        recommend
                                                                        that you
                                                                        review
                                                                        their
                                                                        privacy
                                                                        notice
                                                                        to
                                                                        understand
                                                                        how they
                                                                        collect,
                                                                        use, and
                                                                        share
                                                                        your
                                                                        personal
                                                                        information,
                                                                        and how
                                                                        you can
                                                                        set your
                                                                        privacy
                                                                        preferences
                                                                        on their
                                                                        sites
                                                                        and
                                                                        apps.
                                                                        <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                            <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                              <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                <span styleProp='font-size: 15px;'>
                                                                                  <span styleProp='color: rgb(89, 89, 89);'>
                                                                                    <span styleProp='font-size: 15px;'>
                                                                                      <span styleProp='color: rgb(89, 89, 89);'>
                                                                                        <span data-custom-class='body_text'>
                                                                                          <bdt class='statement-end-if-in-editor'></bdt>
                                                                                        </span>
                                                                                        <bdt class='block-component'>
                                                                                          <span data-custom-class='body_text'>
                                                                                            <bdt class='block-component'></bdt>
                                                                                          </span>
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='inforetain'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                7.
                                                                                HOW
                                                                                LONG
                                                                                DO
                                                                                WE
                                                                                KEEP
                                                                                YOUR
                                                                                INFORMATION?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:{' '}
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          We
                                                                          keep
                                                                          your
                                                                          information
                                                                          for as
                                                                          long
                                                                          as
                                                                          necessary
                                                                          to{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                          fulfill
                                                                          <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                          the
                                                                          purposes
                                                                          outlined
                                                                          in
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          unless
                                                                          otherwise
                                                                          required
                                                                          by
                                                                          law.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        We will
                                                                        only
                                                                        keep
                                                                        your
                                                                        personal
                                                                        information
                                                                        for as
                                                                        long as
                                                                        it is
                                                                        necessary
                                                                        for the
                                                                        purposes
                                                                        set out
                                                                        in this
                                                                        privacy
                                                                        notice,
                                                                        unless a
                                                                        longer
                                                                        retention
                                                                        period
                                                                        is
                                                                        required
                                                                        or
                                                                        permitted
                                                                        by law
                                                                        (such as
                                                                        tax,
                                                                        accounting,
                                                                        or other
                                                                        legal
                                                                        requirements).
                                                                        <bdt class='block-component'></bdt>{' '}
                                                                        No
                                                                        purpose
                                                                        in this
                                                                        notice
                                                                        will
                                                                        require
                                                                        us
                                                                        keeping
                                                                        your
                                                                        personal
                                                                        information
                                                                        for
                                                                        longer
                                                                        than{' '}
                                                                        <span styleProp='font-size: 15px;'>
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt class='block-component'></bdt>
                                                                        the
                                                                        period
                                                                        of time
                                                                        in which
                                                                        users
                                                                        have an
                                                                        account
                                                                        with us
                                                                        <bdt class='block-component'></bdt>
                                                                        <span styleProp='font-size: 15px;'>
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='else-block'></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        When we
                                                                        have no
                                                                        ongoing
                                                                        legitimate
                                                                        business
                                                                        need to
                                                                        process
                                                                        your
                                                                        personal
                                                                        information,
                                                                        we will
                                                                        either
                                                                        delete
                                                                        or{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        anonymize
                                                                        <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                        such
                                                                        information,
                                                                        or, if
                                                                        this is
                                                                        not
                                                                        possible
                                                                        (for
                                                                        example,
                                                                        because
                                                                        your
                                                                        personal
                                                                        information
                                                                        has been
                                                                        stored
                                                                        in
                                                                        backup
                                                                        archives),
                                                                        then we
                                                                        will
                                                                        securely
                                                                        store
                                                                        your
                                                                        personal
                                                                        information
                                                                        and
                                                                        isolate
                                                                        it from
                                                                        any
                                                                        further
                                                                        processing
                                                                        until
                                                                        deletion
                                                                        is
                                                                        possible.
                                                                        <span styleProp='color: rgb(89, 89, 89);'>
                                                                          <bdt class='block-component'></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='infosafe'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                8.
                                                                                HOW
                                                                                DO
                                                                                WE
                                                                                KEEP
                                                                                YOUR
                                                                                INFORMATION
                                                                                SAFE?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:{' '}
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          We aim
                                                                          to
                                                                          protect
                                                                          your
                                                                          personal
                                                                          information
                                                                          through
                                                                          a
                                                                          system
                                                                          of{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                          organizational
                                                                          <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                          and
                                                                          technical
                                                                          security
                                                                          measures.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        We have
                                                                        implemented
                                                                        appropriate
                                                                        and
                                                                        reasonable
                                                                        technical
                                                                        and{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        organizational
                                                                        <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                        security
                                                                        measures
                                                                        designed
                                                                        to
                                                                        protect
                                                                        the
                                                                        security
                                                                        of any
                                                                        personal
                                                                        information
                                                                        we
                                                                        process.
                                                                        However,
                                                                        despite
                                                                        our
                                                                        safeguards
                                                                        and
                                                                        efforts
                                                                        to
                                                                        secure
                                                                        your
                                                                        information,
                                                                        no
                                                                        electronic
                                                                        transmission
                                                                        over the
                                                                        Internet
                                                                        or
                                                                        information
                                                                        storage
                                                                        technology
                                                                        can be
                                                                        guaranteed
                                                                        to be
                                                                        100%
                                                                        secure,
                                                                        so we
                                                                        cannot
                                                                        promise
                                                                        or
                                                                        guarantee
                                                                        that
                                                                        hackers,
                                                                        cybercriminals,
                                                                        or other{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        unauthorized
                                                                        <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                        third
                                                                        parties
                                                                        will not
                                                                        be able
                                                                        to
                                                                        defeat
                                                                        our
                                                                        security
                                                                        and
                                                                        improperly
                                                                        collect,
                                                                        access,
                                                                        steal,
                                                                        or
                                                                        modify
                                                                        your
                                                                        information.
                                                                        Although
                                                                        we will
                                                                        do our
                                                                        best to
                                                                        protect
                                                                        your
                                                                        personal
                                                                        information,
                                                                        transmission
                                                                        of
                                                                        personal
                                                                        information
                                                                        to and
                                                                        from our
                                                                        Services
                                                                        is at
                                                                        your own
                                                                        risk.
                                                                        You
                                                                        should
                                                                        only
                                                                        access
                                                                        the
                                                                        Services
                                                                        within a
                                                                        secure
                                                                        environment.
                                                                        <span styleProp='color: rgb(89, 89, 89);'>
                                                                          <bdt class='statement-end-if-in-editor'></bdt>
                                                                        </span>
                                                                        <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                          <span data-custom-class='body_text'>
                                                                            <bdt class='block-component'></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='infominors'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                9.
                                                                                DO
                                                                                WE
                                                                                COLLECT
                                                                                INFORMATION
                                                                                FROM
                                                                                MINORS?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          {' '}
                                                                          We do
                                                                          not
                                                                          knowingly
                                                                          collect
                                                                          data
                                                                          from
                                                                          or
                                                                          market
                                                                          to{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                          children
                                                                          under
                                                                          18
                                                                          years
                                                                          of age
                                                                          <bdt class='else-block'></bdt>
                                                                          .
                                                                        </em>
                                                                        <bdt class='block-component'></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        We do
                                                                        not
                                                                        knowingly
                                                                        solicit
                                                                        data
                                                                        from or
                                                                        market
                                                                        to
                                                                        children
                                                                        under 18
                                                                        years of
                                                                        age. By
                                                                        using
                                                                        the
                                                                        Services,
                                                                        you
                                                                        represent
                                                                        that you
                                                                        are at
                                                                        least 18
                                                                        or that
                                                                        you are
                                                                        the
                                                                        parent
                                                                        or
                                                                        guardian
                                                                        of such
                                                                        a minor
                                                                        and
                                                                        consent
                                                                        to such
                                                                        minor
                                                                        dependent’s
                                                                        use of
                                                                        the
                                                                        Services.
                                                                        If we
                                                                        learn
                                                                        that
                                                                        personal
                                                                        information
                                                                        from
                                                                        users
                                                                        less
                                                                        than 18
                                                                        years of
                                                                        age has
                                                                        been
                                                                        collected,
                                                                        we will
                                                                        deactivate
                                                                        the
                                                                        account
                                                                        and take
                                                                        reasonable
                                                                        measures
                                                                        to
                                                                        promptly
                                                                        delete
                                                                        such
                                                                        data
                                                                        from our
                                                                        records.
                                                                        If you
                                                                        become
                                                                        aware of
                                                                        any data
                                                                        we may
                                                                        have
                                                                        collected
                                                                        from
                                                                        children
                                                                        under
                                                                        age 18,
                                                                        please
                                                                        contact
                                                                        us at{' '}
                                                                        <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                          <span data-custom-class='body_text'>
                                                                            <bdt class='block-component'></bdt>
                                                                            <bdt class='question'>
                                                                              peonypocketdev@gmail.com
                                                                            </bdt>
                                                                            <bdt class='else-block'></bdt>
                                                                          </span>
                                                                        </span>
                                                                        .
                                                                      </span>
                                                                      <span data-custom-class='body_text'>
                                                                        <bdt class='else-block'>
                                                                          <bdt class='block-component'></bdt>
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='privacyrights'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                10.
                                                                                WHAT
                                                                                ARE
                                                                                YOUR
                                                                                PRIVACY
                                                                                RIGHTS?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          {' '}
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px;'>
                                                                              <span data-custom-class='body_text'>
                                                                                <em>
                                                                                  <bdt class='block-component'></bdt>
                                                                                </em>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          In
                                                                          some
                                                                          regions,
                                                                          such
                                                                          as{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                          Canada
                                                                          <bdt class='statement-end-if-in-editor'></bdt>
                                                                          , you
                                                                          have
                                                                          rights
                                                                          that
                                                                          allow
                                                                          you
                                                                          greater
                                                                          access
                                                                          to and
                                                                          control
                                                                          over
                                                                          your
                                                                          personal
                                                                          information.
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px;'>
                                                                              <span data-custom-class='body_text'>
                                                                                <em>
                                                                                  <bdt class='statement-end-if-in-editor'></bdt>
                                                                                </em>
                                                                              </span>
                                                                            </span>{' '}
                                                                          </span>
                                                                          You
                                                                          may
                                                                          review,
                                                                          change,
                                                                          or
                                                                          terminate
                                                                          your
                                                                          account
                                                                          at any
                                                                          time.
                                                                        </em>
                                                                        <span styleProp='color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px;'>
                                                                            <bdt class='block-component'></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        In some
                                                                        regions
                                                                        (like{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        Canada
                                                                        <bdt class='statement-end-if-in-editor'></bdt>
                                                                        ), you
                                                                        have
                                                                        certain
                                                                        rights
                                                                        under
                                                                        applicable
                                                                        data
                                                                        protection
                                                                        laws.
                                                                        These
                                                                        may
                                                                        include
                                                                        the
                                                                        right
                                                                        (i) to
                                                                        request
                                                                        access
                                                                        and
                                                                        obtain a
                                                                        copy of
                                                                        your
                                                                        personal
                                                                        information,
                                                                        (ii) to
                                                                        request
                                                                        rectification
                                                                        or
                                                                        erasure;
                                                                        (iii) to
                                                                        restrict
                                                                        the
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information;
                                                                        (iv) if
                                                                        applicable,
                                                                        to data
                                                                        portability;
                                                                        and (v)
                                                                        not to
                                                                        be
                                                                        subject
                                                                        to
                                                                        automated
                                                                        decision-making.
                                                                        In
                                                                        certain
                                                                        circumstances,
                                                                        you may
                                                                        also
                                                                        have the
                                                                        right to
                                                                        object
                                                                        to the
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information.
                                                                        You can
                                                                        make
                                                                        such a
                                                                        request
                                                                        by
                                                                        contacting
                                                                        us by
                                                                        using
                                                                        the
                                                                        contact
                                                                        details
                                                                        provided
                                                                        in the
                                                                        section{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        "
                                                                        <bdt class='statement-end-if-in-editor'></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <a
                                                                    data-custom-class='link'
                                                                    href='#contact'
                                                                  >
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          HOW
                                                                          CAN
                                                                          YOU
                                                                          CONTACT
                                                                          US
                                                                          ABOUT
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <bdt class='block-component'></bdt>
                                                                        "
                                                                        <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                        below.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        We will
                                                                        consider
                                                                        and act
                                                                        upon any
                                                                        request
                                                                        in
                                                                        accordance
                                                                        with
                                                                        applicable
                                                                        data
                                                                        protection
                                                                        laws.
                                                                        <bdt class='block-component'></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='withdrawconsent'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <u>
                                                                            Withdrawing
                                                                            your
                                                                            consent:
                                                                          </u>
                                                                        </strong>{' '}
                                                                        If we
                                                                        are
                                                                        relying
                                                                        on your
                                                                        consent
                                                                        to
                                                                        process
                                                                        your
                                                                        personal
                                                                        information,
                                                                        <bdt class='block-component'></bdt>{' '}
                                                                        which
                                                                        may be
                                                                        express
                                                                        and/or
                                                                        implied
                                                                        consent
                                                                        depending
                                                                        on the
                                                                        applicable
                                                                        law,
                                                                        <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                        you have
                                                                        the
                                                                        right to
                                                                        withdraw
                                                                        your
                                                                        consent
                                                                        at any
                                                                        time.
                                                                        You can
                                                                        withdraw
                                                                        your
                                                                        consent
                                                                        at any
                                                                        time by
                                                                        contacting
                                                                        us by
                                                                        using
                                                                        the
                                                                        contact
                                                                        details
                                                                        provided
                                                                        in the
                                                                        section{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        "
                                                                        <bdt class='statement-end-if-in-editor'></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <a
                                                                    data-custom-class='link'
                                                                    href='#contact'
                                                                  >
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          HOW
                                                                          CAN
                                                                          YOU
                                                                          CONTACT
                                                                          US
                                                                          ABOUT
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <bdt class='block-component'></bdt>
                                                                        "
                                                                        <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                        below
                                                                        <bdt class='block-component'></bdt>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px;'>
                                                                    <span data-custom-class='body_text'>
                                                                      However,
                                                                      please
                                                                      note that
                                                                      this will
                                                                      not affect
                                                                      the
                                                                      lawfulness
                                                                      of the
                                                                      processing
                                                                      before its
                                                                      withdrawal
                                                                      nor,
                                                                      <bdt class='block-component'></bdt>{' '}
                                                                      when
                                                                      applicable
                                                                      law
                                                                      allows,
                                                                      <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                      will it
                                                                      affect the
                                                                      processing
                                                                      of your
                                                                      personal
                                                                      information
                                                                      conducted
                                                                      in
                                                                      reliance
                                                                      on lawful
                                                                      processing
                                                                      grounds
                                                                      other than
                                                                      consent.
                                                                      <bdt class='block-component'></bdt>
                                                                    </span>
                                                                  </span>
                                                                  <bdt class='block-component'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span data-custom-class='body_text'></span>
                                                                    </span>
                                                                  </bdt>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px;'>
                                                                    <span data-custom-class='heading_2'>
                                                                      <strong>
                                                                        Account
                                                                        Information
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span data-custom-class='body_text'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      If you
                                                                      would at
                                                                      any time
                                                                      like to
                                                                      review or
                                                                      change the
                                                                      information
                                                                      in your
                                                                      account or
                                                                      terminate
                                                                      your
                                                                      account,
                                                                      you can:
                                                                      <bdt class='forloop-component'></bdt>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <ul>
                                                                  <li styleProp='line-height: 1.5;'>
                                                                    <span data-custom-class='body_text'>
                                                                      <span styleProp='font-size: 15px;'>
                                                                        <bdt class='question'>
                                                                          Log in
                                                                          to
                                                                          your
                                                                          account
                                                                          settings
                                                                          and
                                                                          update
                                                                          your
                                                                          user
                                                                          account.
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span data-custom-class='body_text'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <bdt class='forloop-component'></bdt>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px;'>
                                                                    <span data-custom-class='body_text'>
                                                                      Upon your
                                                                      request to
                                                                      terminate
                                                                      your
                                                                      account,
                                                                      we will
                                                                      deactivate
                                                                      or delete
                                                                      your
                                                                      account
                                                                      and
                                                                      information
                                                                      from our
                                                                      active
                                                                      databases.
                                                                      However,
                                                                      we may
                                                                      retain
                                                                      some
                                                                      information
                                                                      in our
                                                                      files to
                                                                      prevent
                                                                      fraud,
                                                                      troubleshoot
                                                                      problems,
                                                                      assist
                                                                      with any
                                                                      investigations,
                                                                      enforce
                                                                      our legal
                                                                      terms
                                                                      and/or
                                                                      comply
                                                                      with
                                                                      applicable
                                                                      legal
                                                                      requirements.
                                                                    </span>
                                                                  </span>
                                                                  <bdt class='statement-end-if-in-editor'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span data-custom-class='body_text'></span>
                                                                    </span>{' '}
                                                                  </bdt>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <span styleProp='font-size: 15px;'>
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px;'>
                                                                              <span styleProp='color: rgb(89, 89, 89);'>
                                                                                <span data-custom-class='body_text'>
                                                                                  <span styleProp='font-size: 15px;'>
                                                                                    <span styleProp='color: rgb(89, 89, 89);'>
                                                                                      <bdt class='block-component'></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <u>
                                                                            Cookies
                                                                            and
                                                                            similar
                                                                            technologies:
                                                                          </u>
                                                                        </strong>{' '}
                                                                        Most Web
                                                                        browsers
                                                                        are set
                                                                        to
                                                                        accept
                                                                        cookies
                                                                        by
                                                                        default.
                                                                        If you
                                                                        prefer,
                                                                        you can
                                                                        usually
                                                                        choose
                                                                        to set
                                                                        your
                                                                        browser
                                                                        to
                                                                        remove
                                                                        cookies
                                                                        and to
                                                                        reject
                                                                        cookies.
                                                                        If you
                                                                        choose
                                                                        to
                                                                        remove
                                                                        cookies
                                                                        or
                                                                        reject
                                                                        cookies,
                                                                        this
                                                                        could
                                                                        affect
                                                                        certain
                                                                        features
                                                                        or
                                                                        services
                                                                        of our
                                                                        Services.{' '}
                                                                        <bdt class='block-component'>
                                                                          <span styleProp='font-size: 15px;'>
                                                                            <span styleProp='color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>
                                                                                <span styleProp='font-size: 15px;'>
                                                                                  <span styleProp='color: rgb(89, 89, 89);'>
                                                                                    <span styleProp='font-size: 15px;'>
                                                                                      <span styleProp='color: rgb(89, 89, 89);'>
                                                                                        <span styleProp='font-size: 15px;'>
                                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                                            <bdt class='statement-end-if-in-editor'></bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt class='block-component'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span data-custom-class='body_text'></span>
                                                                    </span>
                                                                  </bdt>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span data-custom-class='body_text'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      If you
                                                                      have
                                                                      questions
                                                                      or
                                                                      comments
                                                                      about your
                                                                      privacy
                                                                      rights,
                                                                      you may
                                                                      email us
                                                                      at{' '}
                                                                      <bdt class='question'>
                                                                        peonypocketdev@gmail.com
                                                                      </bdt>
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                  <bdt class='statement-end-if-in-editor'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span data-custom-class='body_text'></span>
                                                                    </span>
                                                                  </bdt>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='DNT'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                11.
                                                                                CONTROLS
                                                                                FOR
                                                                                DO-NOT-TRACK
                                                                                FEATURES
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        Most web
                                                                        browsers
                                                                        and some
                                                                        mobile
                                                                        operating
                                                                        systems
                                                                        and
                                                                        mobile
                                                                        applications
                                                                        include
                                                                        a
                                                                        Do-Not-Track
                                                                        (
                                                                        <bdt class='block-component'></bdt>
                                                                        "DNT"
                                                                        <bdt class='statement-end-if-in-editor'></bdt>
                                                                        )
                                                                        feature
                                                                        or
                                                                        setting
                                                                        you can
                                                                        activate
                                                                        to
                                                                        signal
                                                                        your
                                                                        privacy
                                                                        preference
                                                                        not to
                                                                        have
                                                                        data
                                                                        about
                                                                        your
                                                                        online
                                                                        browsing
                                                                        activities
                                                                        monitored
                                                                        and
                                                                        collected.
                                                                        At this
                                                                        stage no
                                                                        uniform
                                                                        technology
                                                                        standard
                                                                        for{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        recognizing
                                                                        <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                        and
                                                                        implementing
                                                                        DNT
                                                                        signals
                                                                        has been{' '}
                                                                        <bdt class='block-component'></bdt>
                                                                        finalized
                                                                        <bdt class='statement-end-if-in-editor'></bdt>
                                                                        . As
                                                                        such, we
                                                                        do not
                                                                        currently
                                                                        respond
                                                                        to DNT
                                                                        browser
                                                                        signals
                                                                        or any
                                                                        other
                                                                        mechanism
                                                                        that
                                                                        automatically
                                                                        communicates
                                                                        your
                                                                        choice
                                                                        not to
                                                                        be
                                                                        tracked
                                                                        online.
                                                                        If a
                                                                        standard
                                                                        for
                                                                        online
                                                                        tracking
                                                                        is
                                                                        adopted
                                                                        that we
                                                                        must
                                                                        follow
                                                                        in the
                                                                        future,
                                                                        we will
                                                                        inform
                                                                        you
                                                                        about
                                                                        that
                                                                        practice
                                                                        in a
                                                                        revised
                                                                        version
                                                                        of this
                                                                        privacy
                                                                        notice.
                                                                        <bdt class='block-component'></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id='uslaws'
                                                                  styleProp='line-height: 1.5;'
                                                                >
                                                                  <span styleProp='color: rgb(127, 127, 127);'>
                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span
                                                                            id='control'
                                                                            styleProp='color: rgb(0, 0, 0);'
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class='heading_1'>
                                                                                12.
                                                                                DO
                                                                                UNITED
                                                                                STATES
                                                                                RESIDENTS
                                                                                HAVE
                                                                                SPECIFIC
                                                                                PRIVACY
                                                                                RIGHTS?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:{' '}
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          If you
                                                                          are a
                                                                          resident
                                                                          of{' '}
                                                                          <bdt class='forloop-component'></bdt>
                                                                          , you
                                                                          are
                                                                          granted
                                                                          specific
                                                                          rights
                                                                          regarding
                                                                          access
                                                                          to
                                                                          your
                                                                          personal
                                                                          information.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        <strong>
                                                                          What
                                                                          categories
                                                                          of
                                                                          personal
                                                                          information
                                                                          do we
                                                                          collect?
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span data-custom-class='body_text'>
                                                                        We have
                                                                        collected
                                                                        the
                                                                        following
                                                                        categories
                                                                        of
                                                                        personal
                                                                        information
                                                                        in the
                                                                        past
                                                                        twelve
                                                                        (12)
                                                                        months:
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <table styleProp='width: 100%;'>
                                                                  <tbody>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <strong>
                                                                                Category
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <strong>
                                                                                Examples
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; border-right: 1px solid black; border-top: 1px solid black; text-align: center;'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <strong>
                                                                                Collected
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                A.
                                                                                Identifiers
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Contact
                                                                                details,
                                                                                such
                                                                                as
                                                                                real
                                                                                name,
                                                                                alias,
                                                                                postal
                                                                                address,
                                                                                telephone
                                                                                or
                                                                                mobile
                                                                                contact
                                                                                number,
                                                                                unique
                                                                                personal
                                                                                identifier,
                                                                                online
                                                                                identifier,
                                                                                Internet
                                                                                Protocol
                                                                                address,
                                                                                email
                                                                                address,
                                                                                and
                                                                                account
                                                                                name
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; vertical-align: middle; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'>
                                                                                  <bdt class='block-component'></bdt>
                                                                                </bdt>
                                                                                NO
                                                                                <bdt class='statement-end-if-in-editor'>
                                                                                  <bdt class='block-component'></bdt>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <bdt class='block-component'></bdt>
                                                                </div>
                                                                <table styleProp='width: 100%;'>
                                                                  <tbody>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                B
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Protected
                                                                                classification
                                                                                characteristics
                                                                                under
                                                                                state
                                                                                or
                                                                                federal
                                                                                law
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Gender
                                                                                and
                                                                                date
                                                                                of
                                                                                birth
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                              NO
                                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                C
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Commercial
                                                                                information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Transaction
                                                                                information,
                                                                                purchase
                                                                                history,
                                                                                financial
                                                                                details,
                                                                                and
                                                                                payment
                                                                                information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                              NO
                                                                              <bdt class='statement-end-if-in-editor'>
                                                                                <bdt class='block-component'></bdt>
                                                                              </bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                D
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Biometric
                                                                                information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Fingerprints
                                                                                and
                                                                                voiceprints
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'>
                                                                                <bdt class='block-component'>
                                                                                  NO
                                                                                </bdt>
                                                                                <bdt class='statement-end-if-in-editor'></bdt>
                                                                                <bdt class='block-component'></bdt>
                                                                              </bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                E
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Internet
                                                                                or
                                                                                other
                                                                                similar
                                                                                network
                                                                                activity
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Browsing
                                                                                history,
                                                                                search
                                                                                history,
                                                                                online{' '}
                                                                                <bdt class='block-component'></bdt>
                                                                                behavior
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                ,
                                                                                interest
                                                                                data,
                                                                                and
                                                                                interactions
                                                                                with
                                                                                our
                                                                                and
                                                                                other
                                                                                websites,
                                                                                applications,
                                                                                systems,
                                                                                and
                                                                                advertisements
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                              NO
                                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                F
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Geolocation
                                                                                data
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Device
                                                                                location
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                              NO
                                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                G
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Audio,
                                                                                electronic,
                                                                                visual,
                                                                                thermal,
                                                                                olfactory,
                                                                                or
                                                                                similar
                                                                                information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Images
                                                                                and
                                                                                audio,
                                                                                video
                                                                                or
                                                                                call
                                                                                recordings
                                                                                created
                                                                                in
                                                                                connection
                                                                                with
                                                                                our
                                                                                business
                                                                                activities
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                              NO
                                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                H
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Professional
                                                                                or
                                                                                employment-related
                                                                                information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Business
                                                                                contact
                                                                                details
                                                                                in
                                                                                order
                                                                                to
                                                                                provide
                                                                                you
                                                                                our
                                                                                Services
                                                                                at
                                                                                a
                                                                                business
                                                                                level
                                                                                or
                                                                                job
                                                                                title,
                                                                                work
                                                                                history,
                                                                                and
                                                                                professional
                                                                                qualifications
                                                                                if
                                                                                you
                                                                                apply
                                                                                for
                                                                                a
                                                                                job
                                                                                with
                                                                                us
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                              NO
                                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black; width: 33.8274%;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                I
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Education
                                                                                Information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='border-right: 1px solid black; border-top: 1px solid black; width: 51.4385%;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Student
                                                                                records
                                                                                and
                                                                                directory
                                                                                information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='text-align: center; border-right: 1px solid black; border-top: 1px solid black; width: 14.9084%;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <bdt class='forloop-component'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                              NO
                                                                              <bdt class='statement-end-if-in-editor'></bdt>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='border-width: 1px; border-color: black; border-style: solid; width: 33.8274%;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='block-component'></bdt>

                                                                                J
                                                                                <bdt class='statement-end-if-in-editor'></bdt>

                                                                                .
                                                                                Inferences
                                                                                drawn
                                                                                from
                                                                                collected
                                                                                personal
                                                                                information
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='border-bottom: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; width: 51.4385%;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                Inferences
                                                                                drawn
                                                                                from
                                                                                any
                                                                                of
                                                                                the
                                                                                collected
                                                                                personal
                                                                                information
                                                                                listed
                                                                                above
                                                                                to
                                                                                create
                                                                                a
                                                                                profile
                                                                                or
                                                                                summary
                                                                                about,
                                                                                for
                                                                                example,
                                                                                an
                                                                                individual’s
                                                                                preferences
                                                                                and
                                                                                characteristics
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </td>
                                                                      <td styleProp='text-align: center; border-right: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black; width: 14.9084%;'>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          styleProp='line-height: 1.5;'
                                                                        >
                                                                          <span data-custom-class='body_text'>
                                                                            <bdt class='block-component'></bdt>
                                                                            NO
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                                <span data-custom-class='body_text'>
                                                                                  <span styleProp='color: rgb(89, 89, 89);'>
                                                                                    <span data-custom-class='body_text'>
                                                                                      <bdt class='statement-end-if-in-editor'></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div styleProp='line-height: 1.5;'>
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td styleProp='border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;'>
                                                                        <span data-custom-class='body_text'>
                                                                          <bdt class='block-component'></bdt>
                                                                          K
                                                                          <bdt class='statement-end-if-in-editor'></bdt>
                                                                          .
                                                                          Sensitive
                                                                          personal
                                                                          Information
                                                                        </span>
                                                                      </td>
                                                                      <td styleProp='border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;'>
                                                                        <bdt class='block-component'>
                                                                          <span data-custom-class='body_text'></span>
                                                                        </bdt>
                                                                      </td>
                                                                      <td styleProp='border-right: 1px solid black; border-bottom: 1px solid black;'>
                                                                        <div
                                                                          data-empty='true'
                                                                          styleProp='text-align: center;'
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          data-custom-class='body_text'
                                                                          data-empty='true'
                                                                          styleProp='text-align: center; line-height: 1.5;'
                                                                        >
                                                                          <bdt class='block-component'>
                                                                            <span data-custom-class='body_text'></span>
                                                                          </bdt>
                                                                          NO
                                                                          <bdt class='statement-end-if-in-editor'>
                                                                            <span data-custom-class='body_text'></span>
                                                                          </bdt>
                                                                        </div>
                                                                        <div
                                                                          data-empty='true'
                                                                          styleProp='text-align: center;'
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <br />
                                                                </div>
                                                                <div styleProp='line-height: 1.5;'>
                                                                  <bdt class='block-component'>
                                                                    <span data-custom-class='body_text'></span>
                                                                  </bdt>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <bdt class='block-component'></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          We may
                                                                          also
                                                                          collect
                                                                          other
                                                                          personal
                                                                          information
                                                                          outside
                                                                          of
                                                                          these
                                                                          categories
                                                                          through
                                                                          instances
                                                                          where
                                                                          you
                                                                          interact
                                                                          with
                                                                          us in
                                                                          person,
                                                                          online,
                                                                          or by
                                                                          phone
                                                                          or
                                                                          mail
                                                                          in the
                                                                          context
                                                                          of:
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                  <span data-custom-class='body_text'>
                                                                                    <bdt class='block-component'></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <ul>
                                                                    <li styleProp='line-height: 1.5;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span data-custom-class='body_text'>
                                                                            Receiving
                                                                            help
                                                                            through
                                                                            our
                                                                            customer
                                                                            support
                                                                            channels;
                                                                          </span>
                                                                          <span data-custom-class='body_text'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                                <span data-custom-class='body_text'>
                                                                                  <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                    <span data-custom-class='body_text'>
                                                                                      <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                        <span data-custom-class='body_text'>
                                                                                          <span styleProp='font-size: 15px;'>
                                                                                            <span styleProp='color: rgb(89, 89, 89);'>
                                                                                              <span data-custom-class='body_text'>
                                                                                                <bdt class='statement-end-if-in-editor'></bdt>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </li>
                                                                  </ul>
                                                                  <div>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                  <span data-custom-class='body_text'>
                                                                                    <bdt class='block-component'></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <ul>
                                                                    <li styleProp='line-height: 1.5;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span data-custom-class='body_text'>
                                                                            Participation
                                                                            in
                                                                            customer
                                                                            surveys
                                                                            or
                                                                            contests;
                                                                            and
                                                                          </span>
                                                                          <span data-custom-class='body_text'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                                <span data-custom-class='body_text'>
                                                                                  <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                    <span data-custom-class='body_text'>
                                                                                      <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                        <span data-custom-class='body_text'>
                                                                                          <span styleProp='font-size: 15px;'>
                                                                                            <span styleProp='color: rgb(89, 89, 89);'>
                                                                                              <span data-custom-class='body_text'>
                                                                                                <bdt class='statement-end-if-in-editor'></bdt>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </li>
                                                                  </ul>
                                                                  <div>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                  <span data-custom-class='body_text'>
                                                                                    <bdt class='block-component'></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <ul>
                                                                    <li styleProp='line-height: 1.5;'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span data-custom-class='body_text'>
                                                                            Facilitation
                                                                            in
                                                                            the
                                                                            delivery
                                                                            of
                                                                            our
                                                                            Services
                                                                            and
                                                                            to
                                                                            respond
                                                                            to
                                                                            your
                                                                            inquiries.
                                                                          </span>
                                                                          <span data-custom-class='body_text'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                                <span data-custom-class='body_text'>
                                                                                  <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                    <span data-custom-class='body_text'>
                                                                                      <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                        <span data-custom-class='body_text'>
                                                                                          <span styleProp='font-size: 15px;'>
                                                                                            <span styleProp='color: rgb(89, 89, 89);'>
                                                                                              <span data-custom-class='body_text'>
                                                                                                <bdt class='statement-end-if-in-editor'></bdt>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </li>
                                                                  </ul>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <strong>
                                                                            How
                                                                            do
                                                                            we
                                                                            use
                                                                            and
                                                                            share
                                                                            your
                                                                            personal
                                                                            information?
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span
                                                                      data-custom-class='body_text'
                                                                      styleProp='font-size: 15px;'
                                                                    >
                                                                      Learn
                                                                      about how
                                                                      we use
                                                                      your
                                                                      personal
                                                                      information
                                                                      in the
                                                                      section,{' '}
                                                                      <bdt class='block-component'></bdt>
                                                                      "
                                                                      <bdt class='statement-end-if-in-editor'></bdt>
                                                                    </span>
                                                                    <a href='#infouse'>
                                                                      <span
                                                                        data-custom-class='link'
                                                                        styleProp='font-size: 15px;'
                                                                      >
                                                                        HOW DO
                                                                        WE
                                                                        PROCESS
                                                                        YOUR
                                                                        INFORMATION?
                                                                      </span>
                                                                    </a>
                                                                    <span
                                                                      data-custom-class='body_text'
                                                                      styleProp='font-size: 15px;'
                                                                    >
                                                                      <bdt class='block-component'></bdt>
                                                                      "
                                                                    </span>
                                                                    <bdt class='statement-end-if-in-editor'>
                                                                      <span
                                                                        data-custom-class='body_text'
                                                                        styleProp='font-size: 15px;'
                                                                      ></span>
                                                                    </bdt>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <strong>
                                                                            Will
                                                                            your
                                                                            information
                                                                            be
                                                                            shared
                                                                            with
                                                                            anyone
                                                                            else?
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          We may
                                                                          disclose
                                                                          your
                                                                          personal
                                                                          information
                                                                          with
                                                                          our
                                                                          service
                                                                          providers
                                                                          pursuant
                                                                          to a
                                                                          written
                                                                          contract
                                                                          between
                                                                          us and
                                                                          each
                                                                          service
                                                                          provider.
                                                                          Learn
                                                                          more
                                                                          about
                                                                          how we
                                                                          disclose
                                                                          personal
                                                                          information
                                                                          to in
                                                                          the
                                                                          section,{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                          "
                                                                          <bdt class='statement-end-if-in-editor'></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <a href='#whoshare'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span data-custom-class='link'>
                                                                            WHEN
                                                                            AND
                                                                            WITH
                                                                            WHOM
                                                                            DO
                                                                            WE
                                                                            SHARE
                                                                            YOUR
                                                                            PERSONAL
                                                                            INFORMATION?
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </a>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <bdt class='block-component'></bdt>
                                                                          "
                                                                          <bdt class='statement-end-if-in-editor'></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          We may
                                                                          use
                                                                          your
                                                                          personal
                                                                          information
                                                                          for
                                                                          our
                                                                          own
                                                                          business
                                                                          purposes,
                                                                          such
                                                                          as for
                                                                          undertaking
                                                                          internal
                                                                          research
                                                                          for
                                                                          technological
                                                                          development
                                                                          and
                                                                          demonstration.
                                                                          This
                                                                          is not
                                                                          considered
                                                                          to be{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                          "selling"
                                                                          <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                          of
                                                                          your
                                                                          personal
                                                                          information.
                                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          We
                                                                          have
                                                                          not
                                                                          disclosed,
                                                                          sold,
                                                                          or
                                                                          shared
                                                                          any
                                                                          personal
                                                                          information
                                                                          to
                                                                          third
                                                                          parties
                                                                          for a
                                                                          business
                                                                          or
                                                                          commercial
                                                                          purpose
                                                                          in the
                                                                          preceding
                                                                          twelve
                                                                          (12)
                                                                          months.
                                                                          We
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            {' '}
                                                                          </span>
                                                                          will
                                                                          not
                                                                          sell
                                                                          or
                                                                          share
                                                                          personal
                                                                          information
                                                                          in the
                                                                          future
                                                                          belonging
                                                                          to
                                                                          website
                                                                          visitors,
                                                                          users,
                                                                          and
                                                                          other
                                                                          consumers.
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <span styleProp='color: rgb(89, 89, 89);'>
                                                                                <span data-custom-class='body_text'>
                                                                                  <span styleProp='color: rgb(89, 89, 89);'>
                                                                                    <span data-custom-class='body_text'>
                                                                                      <bdt class='statement-end-if-in-editor'></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class='body_text'>
                                                                      <span styleProp='color: rgb(0, 0, 0);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <bdt class='block-component'></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <bdt class='block-component'></bdt>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <bdt class='block-component'>
                                                                      <span styleProp='font-size: 15px;'></span>
                                                                    </bdt>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <bdt class='block-component'></bdt>
                                                                    <bdt class='block-component'>
                                                                      <span styleProp='font-size: 15px;'></span>
                                                                    </bdt>
                                                                    <bdt class='block-component'>
                                                                      <span styleProp='font-size: 15px;'></span>
                                                                    </bdt>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                          <span data-custom-class='body_text'>
                                                                            <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                              <span data-custom-class='body_text'>
                                                                                <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                  <span data-custom-class='body_text'>
                                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                      <span data-custom-class='body_text'>
                                                                                        <bdt class='statement-end-if-in-editor'>
                                                                                          <span data-custom-class='body_text'>
                                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                                              <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                                                <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                                  <span data-custom-class='body_text'>
                                                                                                    <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                                      <span data-custom-class='body_text'>
                                                                                                        <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                                          <span data-custom-class='body_text'>
                                                                                                            <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                                                              <span data-custom-class='body_text'>
                                                                                                                <bdt class='statement-end-if-in-editor'>
                                                                                                                  <bdt class='statement-end-if-in-editor'></bdt>
                                                                                                                </bdt>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <bdt class='block-component'>
                                                                      <span styleProp='font-size: 15px;'></span>
                                                                    </bdt>{' '}
                                                                    <bdt class='block-component'>
                                                                      <span styleProp='font-size: 15px;'></span>
                                                                    </bdt>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id='policyupdates'
                                                                    styleProp='line-height: 1.5;'
                                                                  >
                                                                    <span styleProp='color: rgb(127, 127, 127);'>
                                                                      <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span
                                                                              id='control'
                                                                              styleProp='color: rgb(0, 0, 0);'
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class='heading_1'>
                                                                                  13.
                                                                                  DO
                                                                                  WE
                                                                                  MAKE
                                                                                  UPDATES
                                                                                  TO
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <em>
                                                                            <strong>
                                                                              In
                                                                              Short:{' '}
                                                                            </strong>
                                                                            Yes,
                                                                            we
                                                                            will
                                                                            update
                                                                            this
                                                                            notice
                                                                            as
                                                                            necessary
                                                                            to
                                                                            stay
                                                                            compliant
                                                                            with
                                                                            relevant
                                                                            laws.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          We may
                                                                          update
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          from
                                                                          time
                                                                          to
                                                                          time.
                                                                          The
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          indicated
                                                                          by an
                                                                          updated{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                          "Revised"
                                                                          <bdt class='statement-end-if-in-editor'></bdt>{' '}
                                                                          date
                                                                          and
                                                                          the
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          effective
                                                                          as
                                                                          soon
                                                                          as it
                                                                          is
                                                                          accessible.
                                                                          If we
                                                                          make
                                                                          material
                                                                          changes
                                                                          to
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          we may
                                                                          notify
                                                                          you
                                                                          either
                                                                          by
                                                                          prominently
                                                                          posting
                                                                          a
                                                                          notice
                                                                          of
                                                                          such
                                                                          changes
                                                                          or by
                                                                          directly
                                                                          sending
                                                                          you a
                                                                          notification.
                                                                          We
                                                                          encourage
                                                                          you to
                                                                          review
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          frequently
                                                                          to be
                                                                          informed
                                                                          of how
                                                                          we are
                                                                          protecting
                                                                          your
                                                                          information.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id='contact'
                                                                    styleProp='line-height: 1.5;'
                                                                  >
                                                                    <span styleProp='color: rgb(127, 127, 127);'>
                                                                      <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span
                                                                              id='control'
                                                                              styleProp='color: rgb(0, 0, 0);'
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class='heading_1'>
                                                                                  14.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  CONTACT
                                                                                  US
                                                                                  ABOUT
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          If you
                                                                          have
                                                                          questions
                                                                          or
                                                                          comments
                                                                          about
                                                                          this
                                                                          notice,
                                                                          you
                                                                          may{' '}
                                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'>
                                                                                <bdt class='block-component'></bdt>
                                                                              </bdt>
                                                                              email
                                                                              us
                                                                              at{' '}
                                                                              <bdt class='question'>
                                                                                peonypocketdev@gmail.com
                                                                                or{' '}
                                                                              </bdt>
                                                                              <bdt class='statement-end-if-in-editor'>
                                                                                <bdt class='block-component'></bdt>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                              <span data-custom-class='body_text'>
                                                                                contact
                                                                                us
                                                                                by
                                                                                post
                                                                                at:
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <span styleProp='font-size: 15px;'>
                                                                            <span styleProp='color: rgb(89, 89, 89);'>
                                                                              <span styleProp='color: rgb(89, 89, 89);'>
                                                                                <span data-custom-class='body_text'>
                                                                                  <bdt class='question'>
                                                                                    PeonyPocket
                                                                                    LLC
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class='body_text'>
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <span data-custom-class='body_text'>
                                                                              <bdt class='block-component'></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span data-custom-class='body_text'>
                                                                        <bdt class='question'>
                                                                          __________
                                                                        </bdt>
                                                                        <span styleProp='color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px;'>
                                                                            <bdt class='block-component'></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px;'>
                                                                      <span data-custom-class='body_text'>
                                                                        <bdt class='question'>
                                                                          __________
                                                                        </bdt>
                                                                        <span styleProp='color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px;'>
                                                                            <bdt class='block-component'></bdt>
                                                                            <bdt class='block-component'></bdt>
                                                                            <bdt class='block-component'></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class='body_text'>
                                                                        <span styleProp='color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px;'>
                                                                            <bdt class='statement-end-if-in-editor'></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class='body_text'>
                                                                      <span styleProp='font-size: 15px;'>
                                                                        <span data-custom-class='body_text'>
                                                                          <span styleProp='color: rgb(89, 89, 89);'>
                                                                            <bdt class='statement-end-if-in-editor'>
                                                                              <span styleProp='color: rgb(89, 89, 89);'>
                                                                                <span styleProp='font-size: 15px;'>
                                                                                  <span data-custom-class='body_text'>
                                                                                    <bdt class='block-component'>
                                                                                      <bdt class='block-component'></bdt>
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt class='block-component'>
                                                                        <span styleProp='font-size: 15px;'></span>
                                                                      </bdt>
                                                                      <span styleProp='font-size: 15px;'>
                                                                        <span data-custom-class='body_text'>
                                                                          <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                            <span styleProp='font-size: 15px;'>
                                                                              <span data-custom-class='body_text'>
                                                                                <bdt class='statement-end-if-in-editor'>
                                                                                  <bdt class='block-component'></bdt>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id='request'
                                                                    styleProp='line-height: 1.5;'
                                                                  >
                                                                    <span styleProp='color: rgb(127, 127, 127);'>
                                                                      <span styleProp='color: rgb(89, 89, 89); font-size: 15px;'>
                                                                        <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                          <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                            <span
                                                                              id='control'
                                                                              styleProp='color: rgb(0, 0, 0);'
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class='heading_1'>
                                                                                  15.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  REVIEW,
                                                                                  UPDATE,
                                                                                  OR
                                                                                  DELETE
                                                                                  THE
                                                                                  DATA
                                                                                  WE
                                                                                  COLLECT
                                                                                  FROM
                                                                                  YOU?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <br />
                                                                  </div>
                                                                  <div styleProp='line-height: 1.5;'>
                                                                    <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                      <span styleProp='font-size: 15px; color: rgb(89, 89, 89);'>
                                                                        <span data-custom-class='body_text'>
                                                                          <bdt class='block-component'></bdt>
                                                                          You
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          request
                                                                          access
                                                                          to the
                                                                          personal
                                                                          information
                                                                          we
                                                                          collect
                                                                          from
                                                                          you,
                                                                          change
                                                                          that
                                                                          information,
                                                                          or
                                                                          delete
                                                                          it.{' '}
                                                                          <bdt class='statement-end-if-in-editor'></bdt>
                                                                          To
                                                                          request
                                                                          to
                                                                          review,
                                                                          update,
                                                                          or
                                                                          delete
                                                                          your
                                                                          personal
                                                                          information,
                                                                          please{' '}
                                                                          <bdt class='block-component'></bdt>
                                                                        </span>
                                                                        <span data-custom-class='body_text'>
                                                                          visit:{' '}
                                                                          <bdt class='question'>
                                                                            <a
                                                                              href='http://www.peonypocket.com'
                                                                              target='_blank'
                                                                              data-custom-class='link'
                                                                            >
                                                                              http://www.peonypocket.com
                                                                            </a>
                                                                          </bdt>
                                                                          <bdt class='else-block'></bdt>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class='body_text'>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div styleProp='color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;'>
                                                                  This privacy
                                                                  policy was
                                                                  created using
                                                                  Termly's{' '}
                                                                  <a
                                                                    styleProp='color: rgb(48, 48, 241) !important;'
                                                                    href='https://termly.io/products/privacy-policy-generator/'
                                                                  >
                                                                    Privacy
                                                                    Policy
                                                                    Generator
                                                                  </a>
                                                                  .
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
